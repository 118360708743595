@import url('https://fonts.googleapis.com/css2?family=Racing+Sans+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sansita&display=swap');

.container1{
    max-width: 1170px;
    margin: auto;
    background: fixed;
    /*font-family: 'Sansita', sans-serif;*/
}

.footer{
    background-image: url(../assets/fondoquesos1.jpg);
    padding: 70px 0;
}

.row1{
    display: flex;
    flex-wrap: wrap;
}

.footer-col{
    width: 25%;
    padding: 0 15px;
}

.footer-col h4{
    font-size: 18px;
    color: white;
    margin-bottom: 25px;
    font-weight: 500;
    position:relative;
}

.footer-col h4::before{
    content: '';
    position: absolute;

    bottom: -10px;
    background-color: rgb(255, 213, 0);
    height: 2px;
    box-sizing: border-box;
    width: 80px;
}
.footer-col p{
    color: white;
}
.footer-col ul{
    list-style: none;
}

.footer-col li{
    font-size: 16px;
    color: #ffffffa0;
    display: block;
    margin-bottom: 10px;
}
.footer-col li:hover{
    border-bottom: 3px solid #ffffff;
    width: 100px;
    color:#fff!important;
}

.footer-col li a:hover{
    color: white;
}

.footer-col .social-link a{
    display: inline-block;
    height: 40px;
    width: 40px;
    background: rgba( 255, 255,255,.2);
    margin: 0 10px 19 0px;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    color:white;
    transition: all .5s ease;
    margin-right: 4px;
}
.footer-col .social-link a:hover{
    background: white;
    color: black;
}

.footer1{
    color: white;
    font-size: 13px;
    text-align: center;
}
.footer1 p{
    margin-bottom: 0 !important;
}

@media(max-width:767px){
    .footer-col{
        width: 50%;
        margin-bottom: 30px;
        text-align: justify;
    }
    .footer-col h4{
        position:static;
    }
    .footer-col h4::before{
        position: relative;
    }
    .footer1{
        font-size: 10px;
    }
}

@media(max-width:567px){
    .footer-col{
        width: 100%;

    }
}