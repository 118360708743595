.mapa{
    width: 1000px;
    height: 500px;
    border-radius: 20px;
}

.mapa-1{
    width: 100%;
    height: 500px;
    border-radius: 20px;
    filter: drop-shadow(0px 0px 4px #00000041)
}

.contenedorMapa{
    padding-bottom: 20px;
}

@media (max-width: 991px){
    .mapa-1{
        width: 100%;
    }
}

@media (max-width: 915px){
    .mapa{
        width: 700px;
        height: 360px;
    }    
}

@media (max-width: 820px){
    .mapa{
        width: 500px;
        height: 360px;
    }    
}

@media (max-width: 520px){
    .mapa{
        width: 400px;
        height: 360px;
    }    
}

@media (max-width: 415px){
    .mapa{
        width: 300px;
        height: 360px;
    }    
}

@media (max-width: 280px){
    .mapa{
        width: 250px;
        height: 360px;
    }  
}