@import url('https://fonts.googleapis.com/css2?family=Racing+Sans+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sansita&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fraunces&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}   

.contenedor2{
    height: 100%;
}

.cartas__textos h2{
    font-family: 'Fraunces', serif;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 15px;
    text-shadow: 2px 2px rgb(255, 255, 255);
}



.sucursales1{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    
}

.sucursales2{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.sucursales3{

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 50px;

}

/*.sucursales4{
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 50px;
}*/

.sucursales__fotos{
    display: flex;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.882);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    border:2px dashed rgb(0, 0, 0);
    margin-right: 2px;
    background-color: rgba(255, 255, 255, 0.296);
}

.sucursales__fotos h1{
    text-align: center;
    font-size: 25px;
    font-family: 'Sansita', sans-serif;
}

.sucursales__fotos p{
    text-align: center;
    font-size: 17px;
    font-family: 'Sansita', sans-serif;

}

.sucursales__fotos img{
    width: 300px;
    height: 200px;
}


@media (max-width: 1030px) {
	.sucursales1, .sucursales2, .sucursales3{
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height:auto;
        
	}
}

.contenedor2{
    width: 100%;
    height: 150%;
    background-color: #fdc886;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}