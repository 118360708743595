*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}   
body{
    background-color: black;
    position: relative;
}

.contenedorHistoria {
    background-color: gray;
    width: 100%;
    height: 60vh;
    text-align: center;
    padding-top: 80px;
}

/*.logo{
    width: 350px;
    height: 130px;
    margin: flex;   
    float: left;
    padding-left: 100px;
    padding-top: 10px;
}*/

.banner__textos01 h1 {
    text-align: center;
    font-size: 40px;
    display: flex;
    color: white;
    margin: auto;
    padding-left: 10px;
}

.banner__textos01 p {
    text-align: center;
    color: rgb(255, 255, 255);
    margin: auto;
    text-align: justify;
    padding-left: 10px;
    padding-right: 10px;
    left: 100px;


}


@media (max-width: 1030px) {
	.contenedorHistoria{
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height:100vh;
	}
    .logo{
        width: 350px;
        height: 140px;
        margin: flex;   
        float: left;
        padding-left: 30px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .banner__textos01 h1 {
        margin-top: 0px;
        font-size:30px;
        display: flex;
        align-items: center;
        padding-left: 10px;
    }
    
    .banner__textos01 p {
        display: flex;
        color: rgb(255, 255, 255);
        padding-right: 10px;
        text-align: justify;
        padding-left: 10px;
        font-size: 13px;
        padding-top: 10px;
    }
}