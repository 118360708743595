@import url('https://fonts.googleapis.com/css2?family=Unna&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Prata&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+KR&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+KR&family=Prata&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fraunces&display=swap');


.contenedorProductos{
    width: 100%;
    background-color: #FAE392;
    padding: 20px 0;
    
}

.contenedorProductos_titulo{
    font-family: 'Fraunces', serif;
    font-size: 30px;
    font-weight: bold;
    color: black;
    text-align: center;
    text-shadow: 2px 2px rgb(255, 255, 255);
}

.us-offer{
    display: -webkit-flex;
    flex-direction: row;
    flex-wrap: wrap ;
    justify-content: center;
}

.offer{ 
    width: 300px;
    margin: 0 25px;
}

.offer-img{
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.offer-img img{
    object-fit: cover;
    width: 100%;
    filter: drop-shadow(-2px 10px 7px #070707);
    background-color: #7171710c;
    padding: 18px ;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.offer-price{
    display: flex;
    padding-left: 7px;
    padding-top: 7px;
    flex-direction: column;
    background-color: #ffffff58;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: 0 10px 10px #00000094;
}

.price-subtitle{
    font-size: 12px;
    color: black;
    font-weight: bold;
    font-family: 'Prata', serif;
    
}

.price-price{
    display: flex;
    font-family: 'Unna', serif;
    justify-content: space-between;
}

.price-price h3{
    font-size: 20px;
    font-weight: bold;
    color: black
}

.offer-price p{
    font-size: 12px;
    font-family: 'Noto Serif KR', serif;
    font-weight: bold;
}

@media (max-width: 375px){
    .us-offer{
        flex-direction: column;
		justify-content: center;
		align-items: center;
		height:auto;
    }
}